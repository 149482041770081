import { withPageAuthRequired } from "@auth0/nextjs-auth0";
import { useRouter } from "next/router";
import { useEffect } from "react";

function RedirectPage() {
  const router = useRouter();

  useEffect(() => {
    router.replace("/dashboard");
  }, []);

  return <></>;
}

export default RedirectPage;
